import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import kebabCase from "modules/kebabCase";

import RequiredMark from "components/RequiredMark";

const Select = ({
  className,
  label,
  name,
  quidBase,
  required = false,
  source,
  t,
  translations,
  value,
}) => {
  const [selectedValue, setSelectedValue] = useState(value);

  const inputProps = {
    className: "text-field__input",
    "data-quid": `${quidBase}-select`,
    value,
    name,
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleChange = (event) => {
    const newValue = event.target?.value;
    setSelectedValue(newValue);
  };

  return (
    <div
      className={`form__control-group text-field ${className}`}
      data-quid={quidBase}
    >
      <label
        className="text-field__label"
        data-quid={`${quidBase}-label`}
        htmlFor={name}
      >
        {required && <RequiredMark />} {label}
      </label>

      <select
        {...inputProps}
        value={selectedValue}
        onChange={handleChange}
        required={required}
      >
        {Object.entries(source).map(([key, option]) => (
          <option data-quid={kebabCase(key)} key={key} value={key}>
            {translations ? t(option) : option}
          </option>
        ))}
      </select>
    </div>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  quidBase: PropTypes.string.isRequired,
  required: PropTypes.bool,
  source: PropTypes.objectOf(PropTypes.string),
  translations: PropTypes.bool,
  value: PropTypes.string,
};

Select.defaultProps = {
  className: "grid__cell--1",
  source: {},
  translations: true,
  value: "",
};

export default Select;
