import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, map, mergeMap, pluck } from "rxjs/operators";

const SCOPE = "order-entry/searchCep/";

export const GET_SEARCH_CEP = `${SCOPE}GET_SEARCH_CEP`;
export const GET_SEARCH_CEP_ERROR = `${SCOPE}GET_SEARCH_CEP_ERROR`;
export const SET_SEARCH_CEP = `${SCOPE}SET_SEARCH_CEP`;

const STORE_LOCATOR_SEARCH_CEP_URL =
  "/store-locator-typeahead-service/search/cep";

export function getSearchCep({ query = {} } = {}) {
  return {
    type: GET_SEARCH_CEP,
    power: true,
    query,
    url: STORE_LOCATOR_SEARCH_CEP_URL,
  };
}

export function getSearchCepError(error) {
  return {
    type: GET_SEARCH_CEP_ERROR,
    error,
  };
}

export function setSearchCep(searchCep = []) {
  return {
    type: SET_SEARCH_CEP,
    searchCep,
  };
}

export const initialState = [];

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case SET_SEARCH_CEP:
      return action.searchCep;
    default:
      return state;
  }
}

export const getSearchCepEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(GET_SEARCH_CEP),
    mergeMap((action) =>
      fetch(action).pipe(
        pluck("response"),
        map((searchCep) => {
          return setSearchCep(searchCep);
        }),
        catchError((error) => of(getSearchCepError(error)))
      )
    )
  );

export const epics = combineEpics(getSearchCepEpic);
