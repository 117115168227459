import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import kebabCase from "modules/kebabCase";

import RequiredMark from "components/RequiredMark";

const Input = ({
  className,
  disabled,
  handleBlur,
  handleChange,
  handleFocus,
  isInvalid,
  isRequired,
  label,
  labelRequired,
  quidBase,
  name,
  t,
  type,
  value,
  minLength,
  maxLength,
  readOnly = false,
}) => {
  const inputProps = {
    "data-quid": `${quidBase}-${kebabCase(name)}-input`,
    name,
    onBlur: ({ target: { value: newValue } }) =>
      handleBlur({ [name]: newValue }),
    onChange: ({ target: { value: newValue } }) =>
      handleChange({ [name]: newValue }),
    onFocus: ({ target: { value: currentValue } }) =>
      handleFocus({ [name]: currentValue }),
  };

  return (
    <div
      className={classNames(
        "form__control-group text-field",
        className,
        isInvalid && !readOnly && "error"
      )}
    >
      <label
        className="text-field__label"
        htmlFor={name}
        data-quid={`${quidBase}-${kebabCase(name)}-label`}
      >
        {isRequired || labelRequired ? <RequiredMark /> : null} {label}
      </label>

      <input
        className={classNames(
          "text-field__input",
          isInvalid && !readOnly && "parsley-error",
          readOnly && "input--readOnly"
        )}
        {...(disabled && { disabled })}
        {...inputProps}
        placeholder={label}
        required={isRequired}
        type={type}
        value={value}
        {...(minLength && { minLength })}
        {...(maxLength && { maxLength })}
        readOnly={readOnly}
      />
      {isInvalid && !readOnly && (
        <p
          className="error-message parsley-required"
          data-quid={`${quidBase}-${kebabCase(name)}-error`}
        >
          {t("negative:please_enter_a_valid_value", {
            value: label.toLowerCase(),
          })}
        </p>
      )}
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleFocus: PropTypes.func,
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelRequired: PropTypes.bool,
  name: PropTypes.string.isRequired,
  quidBase: PropTypes.string.isRequired,
  type: PropTypes.string,
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
};

Input.defaultProps = {
  className: "grid__cell--1",
  disabled: false,
  handleBlur: () => {},
  handleFocus: () => {},
  isInvalid: false,
  isRequired: false,
  labelRequired: false,
  type: "text",
  value: "",
  minLength: undefined,
  maxLength: undefined,
};

export default Input;
