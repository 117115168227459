import { translate } from "react-i18next";
import { connect } from "react-redux";

import { customerRoute } from "routes";

import { getCities } from "ducks/city";
import { getCurrentCities } from "ducks/city/selectors";
import { lookupCustomer, updateCustomer } from "ducks/customer";
import {
  getCustomer,
  getCustomerComments,
  getCustomerEmail,
  getCustomerExtension,
  getCustomerFirstName,
  getCustomerId,
  getCustomerLastName,
  getCustomerPhone,
  getCustomerPrefix,
  getIsMinimumCustomerDataComplete,
} from "ducks/customer/selectors";
import {
  getIsFilterDeliveryOnlyEnabled,
  getMarket,
  getMarketCode,
  getMarketConfigs,
  getMarketProfileFields,
} from "ducks/market/selectors";
import { getNeighborhoods } from "ducks/neighborhoods";
import { getCurrentNeighborhoods } from "ducks/neighborhoods/selectors";
import {
  reorder,
  setOrderLocation,
  startOrder,
  updateOrder,
  updateOrderFutureTime,
  updateOrderServiceMethod,
  updateOrderStore,
  updateOrderTiming,
} from "ducks/order";
import {
  getCarryoutOrderLocations,
  getCurrentOrder,
  getCurrentOrderAddressId,
  getCurrentOrderDate,
  getCurrentOrderId,
  getCurrentOrderTime,
  getCurrentOrderTimeList,
  getCurrentOrderTiming,
  getCurrentServiceMethod,
  getDeliveryOrderLocations,
  getOrderAddress,
  getOrderHistory,
  getOrderStoreId,
} from "ducks/order/selectors";
import { clearOrderStatuses, getOrderStatuses } from "ducks/orderStatus";
import { getAllOrderStatuses } from "ducks/orderStatus/selectors";
import { getPlaces } from "ducks/places";
import { getPlacesValues } from "ducks/places/selectors";
import { getRegions } from "ducks/region";
import { getCurrentRegions } from "ducks/region/selectors";
import { getSearchCep } from "ducks/searchCep";
import { getCurrentSearchCep } from "ducks/searchCep/selectors";
import { getStates } from "ducks/states";
import { getStatesValues } from "ducks/states/selectors";
import {
  getCurrentStoreId,
  getHasSelectedStore,
  getServiceHours,
} from "ducks/store/selectors";
import { locateStores } from "ducks/storeLocation";
import {
  getInVariance,
  getLocatedStores,
  getStoreLocatorQuery,
} from "ducks/storeLocation/selectors";
import { getStreetCategories } from "ducks/streetCategories";
import { getStreetCategoriesValues } from "ducks/streetCategories/selectors";
import { getStreets } from "ducks/streets";
import { getStreetsValues } from "ducks/streets/selectors";
import { getPayloadSection } from "selectors/location";
import { getLinkFromRelationship, getSelfLink } from "selectors/related";

import { tabs as customerTabs } from "constants/customer";

import Customer from "components/Customer";

const mapStateToProps = ({ root, ...state }) => ({
  carryoutOrderLocations: getCarryoutOrderLocations(state),
  cities: getCurrentCities(state),
  neighborhoods: getCurrentNeighborhoods(state),
  searchCep: getCurrentSearchCep(state),
  currentOrder: getCurrentOrder(state),
  currentOrderAddressId: getCurrentOrderAddressId(state),
  currentOrderId: getCurrentOrderId(state),
  currentOrderDate: getCurrentOrderDate(state),
  currentOrderTime: getCurrentOrderTime(state),
  currentOrderTimeList: getCurrentOrderTimeList(state),
  currentOrderTiming: getCurrentOrderTiming(state),
  currentSectionName: getPayloadSection(state),
  currentServiceMethod: getCurrentServiceMethod(state),
  currentStoreId: getCurrentStoreId(state),
  customersLink: getLinkFromRelationship("customers")(root),
  customerId: getCustomerId(state),
  deliveryOrderLocations: getDeliveryOrderLocations(state),
  email: getCustomerEmail(state),
  extension: getCustomerExtension(state),
  firstName: getCustomerFirstName(state),
  comments: getCustomerComments(state),
  getOrderAddress: (orderId) => getOrderAddress(orderId)(state),
  getOrderStoreId: (orderId) => getOrderStoreId(orderId)(state),
  getStoreInVariance: (data) => getInVariance(data)(state),
  isMinimumCustomerDataComplete: getIsMinimumCustomerDataComplete(
    getCustomer(state)
  )(state),
  hasSelectedStore: getHasSelectedStore(state),
  isFilterDeliveryOnlyEnabled: getIsFilterDeliveryOnlyEnabled(state),
  lastName: getCustomerLastName(state),
  locatedStores: getLocatedStores(state),
  market: getMarket(state),
  marketCode: getMarketCode(state),
  marketConfigs: getMarketConfigs(state),
  orderHistory: getOrderHistory(state),
  orderStatuses: getAllOrderStatuses(state),
  orderDescriptionsLink: getLinkFromRelationship("orderDescriptions")(root),
  places: getPlacesValues(state),
  phone: getCustomerPhone(state),
  prefix: getCustomerPrefix(state),
  profileFields: getMarketProfileFields(state),
  regions: getCurrentRegions(state),
  rootLink: getSelfLink(root),
  serviceHours: getServiceHours(state),
  states: getStatesValues(state),
  streetCategories: getStreetCategoriesValues(state),
  streets: getStreetsValues(state),
  storeLocatorQuery: getStoreLocatorQuery(state),
  storesLink: getLinkFromRelationship("stores")(root),
});

const mapDispatchToProps = (dispatch) => ({
  clearOrderStatuses: () => dispatch(clearOrderStatuses()),
  getCities: (query) => dispatch(getCities({ query })),
  getNeighborhoods: (query) => dispatch(getNeighborhoods({ query })),
  getSearchCep: (query) => dispatch(getSearchCep({ query })),
  getOrderStatuses: (query) => dispatch(getOrderStatuses(query)),
  getPlaces: (places) => dispatch(getPlaces(places)),
  getStates: (states) => dispatch(getStates(states)),
  getStreetCategories: (states) => dispatch(getStreetCategories(states)),
  getStreets: ({ url, query, power }) =>
    dispatch(getStreets({ url, query, power })),
  getRegions: (countryCode) => dispatch(getRegions(countryCode)),
  goToCustomer: (section) => dispatch(customerRoute(section)),
  locateStores: (query) => dispatch(locateStores(query)),
  lookupCustomer: (customer) => dispatch(lookupCustomer(customer)),
  reorder: (order) => dispatch(reorder(order)),
  setOrderLocation: (location) => dispatch(setOrderLocation(location)),
  startOrder: (order) => dispatch(startOrder(order)),
  updateCustomer: (customer) => dispatch(updateCustomer(customer)),
  updateOrder: (order) => dispatch(updateOrder(order)),
  updateOrderFutureTime: (order) => dispatch(updateOrderFutureTime(order)),
  updateOrderServiceMethod: (order) =>
    dispatch(updateOrderServiceMethod(order)),
  updateOrderStore: (store) => dispatch(updateOrderStore(store)),
  updateOrderTiming: (order) => dispatch(updateOrderTiming(order)),
});

const mergeProps = (
  {
    currentOrder,
    currentSectionName,
    customerId,
    customersLink,
    getOrderAddress: stateGetOrderAddress,
    getOrderStoreId: stateGetOrderStoreId,
    getStoreInVariance: stateGetStoreInVariance,
    isFilterDeliveryOnlyEnabled,
    isMinimumCustomerDataComplete,
    market,
    marketConfigs,
    orderDescriptionsLink,
    places,
    profileURL,
    rootLink,
    states,
    storesLink,
    streetCategories,
    streets,
    ...state
  },
  {
    getOrderStatuses: dispatchGetOrderStatuses,
    getPlaces: dispatchGetPlaces,
    getRegions: dispatchGetRegions,
    getStore: dispatchGetStore,
    locateStores: dispatchLocateStores,
    lookupCustomer: dispatchLookupCustomer,
    reorder: dispatchReorder,
    setOrderLocation: dispatchSetOrderLocation,
    startOrder: dispatchStartOrder,
    updateCustomer: dispatchUpdateCustomer,
    updateOrder: dispatchUpdateOrder,
    updateOrderFutureTime: dispatchUpdateOrderFutureTime,
    updateOrderServiceMethod: dispatchUpdateOrderServiceMethod,
    updateOrderStore: dispatchUpdateOrderStore,
    updateOrderTiming: dispatchUpdateOrderTiming,
    getStates: dispatchGetStates,
    getStreetCategories: dispatchGetStreetCategories,
    getStreets: dispatchGetStreets,
    ...dispatch
  },
  props
) => {
  const {
    currentOrderAddressId,
    currentOrderId,
    currentOrderTiming,
    currentStoreId,
    marketCode,
  } = state;

  //TODO: write generic tab builder func
  const tabsIncludesPath = customerTabs
    .map(({ path: existingPath }) => existingPath)
    .includes(currentSectionName);
  const { FORCE_CUSTOMER_REQUIRED_FIELDS } = marketConfigs;
  const tabs = customerTabs.map(({ path, ...tab }, index) => ({
    ...tab,
    active:
      currentSectionName && tabsIncludesPath
        ? currentSectionName === path
        : index === 0,
    path,
    disabled:
      index !== 0 &&
      FORCE_CUSTOMER_REQUIRED_FIELDS &&
      !isMinimumCustomerDataComplete,
  }));

  const { path: currentPath, text: title } = tabs.find(({ active }) => active);

  const futureTimeLink = getLinkFromRelationship("futureTime")(currentOrder);
  const orderAddressLink = getLinkFromRelationship("address")(currentOrder);
  const orderCustomerLink = getLinkFromRelationship("customer")(currentOrder);
  const orderStoreLink = getLinkFromRelationship("store")(currentOrder);
  const ordersLink = getLinkFromRelationship("orders")(market);

  const orderServiceMethodLink =
    getLinkFromRelationship("serviceMethod")(currentOrder);
  const marketStatesLink = getLinkFromRelationship("states")(market);
  const marketStreetCategoriesLink =
    getLinkFromRelationship("streetCategories")(market);

  return Object.assign(
    {
      currentPath,
      tabs,
      title,
      getOrderStatuses: (phone) =>
        dispatchGetOrderStatuses({
          phone,
          url: orderDescriptionsLink,
        }),
      getPlaces: ({ url }) => dispatchGetPlaces({ url, query: { marketCode } }),
      getRegions: () => dispatchGetRegions({ marketCode }),
      getStates: () => dispatchGetStates({ url: marketStatesLink }),
      getStreetCategories: () =>
        dispatchGetStreetCategories({
          url: marketStreetCategoriesLink,
        }),
      getStreets: ({
        state: locatorStateId,
        street,
        streetCategory,
        countryCode,
        customHref,
        city,
        streetLimit,
        power = false,
      }) => {
        const {
          relationships: {
            streets: {
              links: {
                related: { href },
              },
            },
          },
        } = states.find(({ id }) => id === locatorStateId) || {
          relationships: {
            streets: { links: { related: { href: "" } } },
          },
        };

        const query = {
          street: streetCategory
            ? `${streetCategory} ${street}`.trim()
            : street,
          ...(countryCode ? { countryCode } : { marketCode }),
          ...(city && { city }),
          ...(streetLimit && { streetLimit }),
        };

        const url = customHref || href;

        return dispatchGetStreets({
          url,
          query,
          power,
        });
      },
      getStore: (storeId) =>
        dispatchUpdateOrderStore({
          addressId: currentOrderAddressId,
          orderId: currentOrderId,
          storeId,
          url: orderStoreLink,
        }),
      getStoreInVariance: (storeId) => stateGetStoreInVariance(storeId),
      isFilterDeliveryOnlyEnabled,
      isMinimumCustomerDataComplete,
      locateStores: (query) =>
        dispatchLocateStores({
          ...query,
          orderId: currentOrderId,
          orderStoreLink,
        }),
      lookupCustomer: ({ phone, prefix, extension }) => {
        dispatchLookupCustomer({
          extension,
          phone,
          prefix,
          url: customersLink,
        });
      },
      marketConfigs,
      places,
      reorder: (url) => dispatchReorder({ currentOrderId, url }),
      setOrderLocation: ({ orderId = "" } = {}) =>
        dispatchSetOrderLocation({
          address: stateGetOrderAddress(orderId),
          orderAddressLink,
          orderId: currentOrderId,
          orderStoreLink,
          storeId: stateGetOrderStoreId(orderId),
        }),
      setOrderTiming: (orderTiming) =>
        dispatchUpdateOrderTiming({
          currentOrderTiming,
          orderId: currentOrderId,
          orderTiming,
          url: futureTimeLink,
        }),
      setServiceMethod: (serviceMethod) =>
        dispatchUpdateOrderServiceMethod({
          orderId: currentOrderId,
          serviceMethod,
          url: orderServiceMethodLink,
        }),
      states,
      startOrder: (order) =>
        !currentOrderId &&
        dispatchStartOrder({
          ...order,
          storeId: currentStoreId,
          url: ordersLink,
        }),
      streetCategories,
      streets,
      updateCustomer: (customer) =>
        dispatchUpdateCustomer({
          ...customer,
          customerId,
          url: orderCustomerLink,
        }),
      updateOrder: (order) =>
        dispatchUpdateOrder({ orderId: currentOrderId, ...order }),
      updateOrderFutureTime: (order) =>
        dispatchUpdateOrderFutureTime({
          ...order,
          orderId: currentOrderId,
          url: futureTimeLink,
        }),
    },
    state,
    dispatch,
    props
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(translate(["customer", "shared"])(Customer));
