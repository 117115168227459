import React from "react";

import PropTypes from "prop-types";

import Locations from "components/Customer/Locations";
import Orders from "components/Customer/Orders";
import Profile from "components/Customer/Profile";
import Page from "components/Page";
import Tabs from "components/Tabs";

const Customer = ({
  carryoutOrderLocations,
  cities,
  clearFutureTimeError,
  clearOrderStatuses,
  comments,
  currentOrderDate,
  currentOrderId,
  currentOrderTime,
  currentOrderTimeList,
  currentOrderTiming,
  currentPath,
  currentServiceMethod,
  currentStoreId,
  deliveryOrderLocations,
  email,
  extension,
  firstName,
  getCities,
  getNeighborhoods,
  getSearchCep,
  getOrderStatuses,
  getPlaces,
  getStates,
  getStreetCategories,
  getStreets,
  getRegions,
  getStore,
  getStoreInVariance,
  goToCustomer,
  hasSelectedStore,
  isFilterDeliveryOnlyEnabled,
  isMinimumCustomerDataComplete,
  lastName,
  locateStores,
  locatedStores,
  lookupCustomer,
  maps,
  marketCode,
  marketConfigs,
  neighborhoods,
  searchCep,
  orderHistory,
  orderStatuses,
  phone,
  prefix,
  places,
  profileFields,
  regions,
  reorder,
  serviceHours,
  setOrderLocation,
  setOrderTiming,
  setFutureTimeError,
  setServiceMethod,
  showFutureTimeError,
  sidebar,
  startOrder,
  storeLocatorQuery,
  streets,
  t,
  tabs,
  title,
  updateCustomer,
  updateOrder,
  states,
  streetCategories,
  updateOrderFutureTime,
}) => {
  const tabbar = <Tabs goTo={goToCustomer} t={t} tabs={tabs} />;

  let subsection;

  switch (currentPath) {
    case "locations":
      subsection = (
        <Locations
          cities={cities}
          currentOrderTime={currentOrderTime}
          currentServiceMethod={currentServiceMethod}
          currentStoreId={currentStoreId}
          getCities={getCities}
          getNeighborhoods={getNeighborhoods}
          getSearchCep={getSearchCep}
          getRegions={getRegions}
          getPlaces={getPlaces}
          getStore={getStore}
          getStoreInVariance={getStoreInVariance}
          getStreetCategories={getStreetCategories}
          getStreets={getStreets}
          hasSelectedStore={hasSelectedStore}
          isFilterDeliveryOnlyEnabled={isFilterDeliveryOnlyEnabled}
          locateStores={locateStores}
          locatedStores={locatedStores}
          maps={maps}
          marketCode={marketCode}
          marketConfigs={marketConfigs}
          neighborhoods={neighborhoods}
          searchCep={searchCep}
          regions={regions}
          places={places}
          storeLocatorQuery={storeLocatorQuery}
          t={t}
          getStates={getStates}
          states={states}
          streetCategories={streetCategories}
          streets={streets}
        />
      );
      break;
    case "orders":
      subsection = (
        <Orders
          clearOrderStatuses={clearOrderStatuses}
          getOrderStatuses={getOrderStatuses}
          orderHistory={orderHistory}
          orderStatuses={orderStatuses}
          phone={prefix ? `${prefix}${phone}`.trim() : phone}
          reorder={reorder}
          t={t}
        />
      );
      break;
    case "profile":
    default:
      subsection = (
        <Profile
          carryoutOrderLocations={carryoutOrderLocations}
          clearFutureTimeError={clearFutureTimeError}
          comments={comments}
          currentOrderDate={currentOrderDate}
          currentOrderId={currentOrderId}
          currentOrderTime={currentOrderTime}
          currentOrderTimeList={currentOrderTimeList}
          currentOrderTiming={currentOrderTiming}
          currentServiceMethod={currentServiceMethod}
          deliveryOrderLocations={deliveryOrderLocations}
          email={email}
          extension={extension}
          firstName={firstName}
          isMinimumCustomerDataComplete={isMinimumCustomerDataComplete}
          lastName={lastName}
          lookupCustomer={lookupCustomer}
          marketCode={marketCode}
          marketConfigs={marketConfigs}
          phone={phone}
          prefix={prefix}
          profileFields={profileFields}
          serviceHours={serviceHours}
          setOrderLocation={setOrderLocation}
          setFutureTimeError={setFutureTimeError}
          setOrderTiming={setOrderTiming}
          setServiceMethod={setServiceMethod}
          showFutureTimeError={showFutureTimeError}
          startOrder={startOrder}
          t={t}
          updateCustomer={updateCustomer}
          updateOrder={updateOrder}
          updateOrderFutureTime={updateOrderFutureTime}
        />
      );
      break;
  }

  return (
    <Page id="customer" sidebar={sidebar} tabs={tabbar} title={t(title)}>
      {subsection}
    </Page>
  );
};

Customer.propTypes = {
  carryoutOrderLocations: PropTypes.arrayOf(PropTypes.object),
  cities: PropTypes.objectOf(PropTypes.string).isRequired,
  clearFutureTimeError: PropTypes.func.isRequired,
  clearOrderStatuses: PropTypes.func.isRequired,
  comments: PropTypes.string.isRequired,
  currentOrderDate: PropTypes.instanceOf(Date),
  currentOrderId: PropTypes.string,
  currentOrderTime: PropTypes.string,
  currentOrderTimeList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  currentOrderTiming: PropTypes.string,
  currentPath: PropTypes.string,
  currentServiceMethod: PropTypes.string,
  currentStoreId: PropTypes.string,
  deliveryOrderLocations: PropTypes.arrayOf(PropTypes.object),
  email: PropTypes.string,
  extension: PropTypes.string,
  firstName: PropTypes.string,
  getCities: PropTypes.func.isRequired,
  getNeighborhoods: PropTypes.func.isRequired,
  getSearchCep: PropTypes.func.isRequired,
  getOrderStatuses: PropTypes.func.isRequired,
  getPlaces: PropTypes.func.isRequired,
  getRegions: PropTypes.func.isRequired,
  getStates: PropTypes.func.isRequired,
  getStore: PropTypes.func.isRequired,
  getStoreInVariance: PropTypes.func.isRequired,
  getStreetCategories: PropTypes.func.isRequired,
  getStreets: PropTypes.func.isRequired,
  goToCustomer: PropTypes.func.isRequired,
  hasSelectedStore: PropTypes.bool,
  isFilterDeliveryOnlyEnabled: PropTypes.bool,
  isMinimumCustomerDataComplete: PropTypes.bool,
  lastName: PropTypes.string,
  locateStores: PropTypes.func.isRequired,
  locatedStores: PropTypes.objectOf(PropTypes.object).isRequired,
  lookupCustomer: PropTypes.func.isRequired,
  maps: PropTypes.objectOf(PropTypes.any).isRequired,
  marketCode: PropTypes.string.isRequired,
  marketConfigs: PropTypes.shape({
    DEFAULT_CARRYOUT_WAIT_TIME: PropTypes.string,
    DEFAULT_DELIVERY_WAIT_TIME: PropTypes.string,
  }).isRequired,
  neighborhoods: PropTypes.objectOf(PropTypes.string).isRequired,
  searchCep: PropTypes.arrayOf(PropTypes.object).isRequired,
  orderHistory: PropTypes.objectOf(PropTypes.any),
  orderStatuses: PropTypes.objectOf(PropTypes.any),
  phone: PropTypes.string,
  prefix: PropTypes.string,
  places: PropTypes.arrayOf(PropTypes.string).isRequired,
  profileFields: PropTypes.objectOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  regions: PropTypes.objectOf(PropTypes.string).isRequired,
  reorder: PropTypes.func.isRequired,
  serviceHours: PropTypes.objectOf(PropTypes.object),
  setOrderLocation: PropTypes.func.isRequired,
  setOrderTiming: PropTypes.func.isRequired,
  setFutureTimeError: PropTypes.func.isRequired,
  setServiceMethod: PropTypes.func.isRequired,
  showFutureTimeError: PropTypes.bool,
  sidebar: PropTypes.node,
  startOrder: PropTypes.func.isRequired,
  states: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string }))
    .isRequired,
  streetCategories: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, option: PropTypes.string })
  ).isRequired,
  streets: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      placeId: PropTypes.string,
    })
  ).isRequired,
  storeLocatorQuery: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
    ])
  ).isRequired,
  t: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      id: PropTypes.string,
      path: PropTypes.string,
      text: PropTypes.string,
      translate: PropTypes.bool,
    })
  ),
  title: PropTypes.string,
  updateCustomer: PropTypes.func.isRequired,
  updateOrder: PropTypes.func.isRequired,
  updateOrderFutureTime: PropTypes.func.isRequired,
};

Customer.defaultProps = {
  carryoutOrderLocations: [],
  comments: "",
  currentOrderDate: undefined,
  currentOrderId: "",
  currentOrderTime: "",
  currentOrderTimeList: [],
  currentOrderTiming: undefined,
  currentPath: "profile",
  currentServiceMethod: "",
  currentStoreId: "",
  deliveryOrderLocations: [],
  email: "",
  extension: "",
  firstName: "",
  hasSelectedStore: true,
  isFilterDeliveryOnlyEnabled: true,
  isMinimumCustomerDataComplete: false,
  lastName: "",
  orderHistory: {},
  orderStatuses: {},
  phone: "",
  prefix: "",
  profileFields: {},
  serviceHours: {},
  showFutureTimeError: false,
  sidebar: null,
  tabs: [
    {
      icon: "person",
      id: "profile",
      path: "profile",
      text: "customer:profile.title",
      translate: true,
    },
  ],
  title: "",
};

export default Customer;
