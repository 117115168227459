import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";

import { ADDRESS, ADDRESS_FIELDS } from "constants/address";
import { DEFAULT_LOCATOR_STRATEGY } from "constants/locatorStrategy";
import { MARKET_CENTER } from "constants/market";
import { SERVICE_METHOD } from "constants/order";

import "components/StoreLocator/Market/MX.css";
import GoogleMapsTypeAhead from "components/StoreLocator/Strategy/GoogleMapsTypeAhead";
import Input from "components/StoreLocator/Strategy/Input";
import PinDrop, { MAP_OPTIONS } from "components/StoreLocator/Strategy/PinDrop";
import Select from "components/StoreLocator/Strategy/Select";

const SHOW_ADDRESS_FIELDS_1 = Object.freeze({
  fields: [
    ADDRESS_FIELDS[ADDRESS.POSTAL_CODE],
    ADDRESS_FIELDS[ADDRESS.STATE],
    ADDRESS_FIELDS[ADDRESS.CITY],
  ],
});

const SHOW_ADDRESS_FIELDS_2 = Object.freeze({
  fields: [
    ADDRESS_FIELDS[ADDRESS.STREET_NAME],
    ADDRESS_FIELDS[ADDRESS.STREET_NUMBER],
    ADDRESS_FIELDS[ADDRESS.UNIT_NUMBER],
  ],
});

const optionalFields = [ADDRESS.UNIT_NUMBER];

const placesMethods = Object.freeze(["Carryout", "Delivery"]);
const MARKET_ZOOM = 6;

class MX extends Component {
  constructor(props) {
    super(props);

    this.map = React.createRef();

    this.state = {
      invalid: [],
      mNeighborhood: "",
    };

    this.setDefaultLocatorStrategy = this.setDefaultLocatorStrategy.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handlePinDrop = this.handlePinDrop.bind(this);
    this.validateInputs = this.validateInputs.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { maps, serviceMethod, setUsesPlaces } = this.props;
    this.geocoderService = new maps.Geocoder();
    this.geocoderOK = maps.GeocoderStatus.OK;
    placesMethods.includes(serviceMethod) && setUsesPlaces(true);
  }

  validateInputs() {
    const propKeys = Object.keys(this.props);

    // All input fields except for neighborhood are required.
    this.setState({
      invalid: Object.keys(ADDRESS_FIELDS).filter(
        (field) =>
          propKeys.includes(field) &&
          this.props[field]?.length === 0 &&
          !optionalFields.includes(field)
      ),
    });
  }

  handleFocus(update) {
    const [fieldName] = Object.keys(update);

    this.setState({
      invalid: this.state.invalid.filter(
        (invalidFieldName) => fieldName !== invalidFieldName
      ),
    });
  }

  setDefaultLocatorStrategy() {
    this.handleClear();
    this.props.setLocatorStrategy(DEFAULT_LOCATOR_STRATEGY);
  }

  handleChange(updates) {
    this.setState({ ...updates });
  }

  handleSearch() {
    const { address } = this.props;

    this.getGeocodedResult({ address }, ({ geometry }) => {
      this.map.current.dropPin(geometry.location);
    });
  }

  handleClear() {
    const updates = {
      state: "",
      city: "",
      postalCode: "",
      streetName: "",
      streetNumber: "",
      neighborhood: "",
      address: "",
    };

    this.props.handleChange(updates);
  }

  transformAddress(address) {
    const newAddress = {};
    address.forEach((component) => {
      const { types, long_name, short_name } = component;
      types.forEach((type) => {
        if (!newAddress[type]) {
          newAddress[type] = {};
        }
        newAddress[type] = {
          long_name,
          short_name,
        };
      });
    });

    return newAddress;
  }

  getGeocodedResult(request, callback) {
    this.geocoderService.geocode(request, (response, status) => {
      if (status !== this.geocoderOK) {
        this.props.onError(status);
        return;
      }

      // If we have an address of type "route", use it.
      // If not, use the first available address component.
      const { address_components, geometry, place_id } = response[0];
      const formattedAddress = this.transformAddress(address_components);
      const { locality, route, sublocality } = formattedAddress;
      const streetName = route?.long_name || "";
      const streetNameUnnamed = !streetName.match(/unnamed/i)
        ? route?.long_name
        : sublocality?.short_name || "";

      const address =
        `${streetNameUnnamed}, ${locality?.short_name}, MX`.trim();
      callback({
        address,
        addressComponents: address_components,
        geometry,
        placeId: place_id,
      });
    });
  }

  mapAddressComponentsToQuery(addressComponents, query = {}) {
    return addressComponents.reduce((all) => {
      const formattedAddress = this.transformAddress(addressComponents);
      const { locality, route, sublocality, street_number } = formattedAddress;
      const streetName = route?.long_name || "";
      const streetNameUnnamed = !streetName.match(/unnamed/i)
        ? route?.long_name
        : sublocality?.short_name || "";

      const address =
        `${streetNameUnnamed}, ${locality?.short_name}, MX`.trim();

      return Object.assign(all, {
        streetNumber: street_number?.short_name,
        streetName: route?.short_name,
        address,
      });
    }, query);
  }

  handlePinDrop(latLng) {
    this.getGeocodedResult(
      { location: latLng },
      ({ addressComponents, placeId, geometry }) => {
        const { postal_code, sublocality } =
          this.transformAddress(addressComponents);

        if (postal_code?.short_name) {
          this.props.getSearchCep({
            cep: postal_code.short_name,
            countryCode: "MX",
          });
        }

        this.setState((prevState) => ({
          ...prevState,
          mNeighborhood: sublocality?.short_name,
        }));

        const updateObject = Object.assign(
          {},
          ...Object.keys(ADDRESS_FIELDS)
            .filter((field) => field !== ADDRESS.ADDRESS_TYPE)
            .map((field) => ({ [field]: "" })),
          this.mapAddressComponentsToQuery(addressComponents, placeId),
          {
            latitude: geometry.location.lat(),
            longitude: geometry.location.lng(),
          },
          {
            placeId,
          }
        );
        this.props.handleChange(updateObject);
      }
    );
  }

  render() {
    const {
      address,
      deliveryInstructions,
      searchCep,
      handleChange,
      maps,
      marketConfigs: { DELIVERY_INSTRUCTIONS_MAX_LENGTH },
      serviceMethod,
      t,
      locatorStrategy,
    } = this.props;
    const isDefaultLocatorStrategy =
      locatorStrategy === DEFAULT_LOCATOR_STRATEGY;

    const updatedNeighborhood =
      searchCep?.length > 0
        ? searchCep.reduce((combinedResult, cepResult) => {
            combinedResult[cepResult.neighborhood] = cepResult.neighborhood;
            return combinedResult;
          }, {})
        : {};

    const { handleFocus, validateInputs } = this;
    const { invalid, mNeighborhood } = this.state;

    const searchCepMapper = {
      state: searchCep[0]?.state,
      postalCode: searchCep[0]?.cep,
      city: searchCep[0]?.city,
      neighborhood: searchCep[0]?.neighborhood,
    };
    const neighborhoodValue = mNeighborhood?.length
      ? mNeighborhood
      : searchCepMapper.neighborhood || "";

    const countryCodeList =
      process.env.NODE_ENV === "production" ? ["MX"] : ["MX", "US"];

    switch (serviceMethod) {
      case SERVICE_METHOD.DELIVERY:
        return (
          <div className="grid">
            {isDefaultLocatorStrategy && (
              <div className="grid__cell--1 grid__cell--1/2@desktop margin__bottom--1_25rem">
                <PinDrop
                  mapOptions={Object.assign({}, MAP_OPTIONS, {
                    mapTypeControl: true,
                  })}
                  mapCenter={MARKET_CENTER.MEXICO}
                  mapZoom={MARKET_ZOOM}
                  maps={maps}
                  onChange={this.handlePinDrop}
                  ref={this.map}
                />
              </div>
            )}
            <div className="grid__cell--1 grid__cell--1/2@desktop">
              <div className="grid">
                {isDefaultLocatorStrategy && (
                  <Fragment>
                    <GoogleMapsTypeAhead
                      className="grid__cell--1"
                      input={address}
                      label={t(ADDRESS_FIELDS[ADDRESS.ADDRESS].label)}
                      maps={maps}
                      name="address"
                      onChange={({ address: addressValue, placeId }) =>
                        handleChange({
                          address: addressValue,
                          placeId,
                        })
                      }
                      options={{
                        componentRestrictions: {
                          country: countryCodeList,
                        },
                        types: ["geocode"],
                      }}
                      quidBase="store-locator-address"
                      required
                    />

                    {SHOW_ADDRESS_FIELDS_1.fields.map(
                      ({ label, mapping: key }) => (
                        <Input
                          className="grid__cell--1 grid__cell--1/2@desktop"
                          handleBlur={validateInputs}
                          handleChange={handleChange}
                          handleFocus={handleFocus}
                          isInvalid={
                            invalid.includes(key) &&
                            this.props[key].length === 0
                          }
                          isRequired={!optionalFields.includes(key)}
                          key={key}
                          label={t(label)}
                          name={key}
                          quidBase="store-locator-address-field"
                          t={t}
                          value={searchCepMapper[key]}
                          readOnly={true}
                        />
                      )
                    )}

                    <Select
                      className="grid__cell--1 grid__cell--1/2@desktop"
                      handleChange={handleChange}
                      label={t("customer:locations.address.neighborhood")}
                      name="neighborhood"
                      quidBase="store-locator-neighborhood"
                      source={Object.freeze({
                        selectneighborhood: t(
                          "customer:locations.address.select_neighborhood"
                        ),
                        ...updatedNeighborhood,
                      })}
                      t={t}
                      value={neighborhoodValue}
                      required={true}
                      translations={false}
                    />

                    {SHOW_ADDRESS_FIELDS_2.fields.map(
                      ({ label, mapping: key }) => (
                        <Input
                          className="grid__cell--1 grid__cell--1/2@desktop"
                          handleBlur={validateInputs}
                          handleChange={handleChange}
                          handleFocus={handleFocus}
                          isInvalid={
                            invalid.includes(key) &&
                            this.props[key].length === 0
                          }
                          isRequired={!optionalFields.includes(key)}
                          key={key}
                          label={t(label)}
                          name={key}
                          quidBase="store-locator-address-field"
                          t={t}
                          value={this.props[key]}
                        />
                      )
                    )}
                  </Fragment>
                )}

                <Input
                  className="grid__cell--1"
                  handleChange={handleChange}
                  label={t(ADDRESS_FIELDS[ADDRESS.DELIVERY_INSTRUCTIONS].label)}
                  name="deliveryInstructions"
                  quidBase="store-locator-address-field"
                  value={deliveryInstructions}
                  maxLength={DELIVERY_INSTRUCTIONS_MAX_LENGTH}
                  t={t}
                />

                <div className="grid__cell--1">
                  <div className="grid form-controls">
                    <button
                      className="btn grid__cell--1/2 grid__cell--1/4@widescreen"
                      data-icon="clear"
                      data-quid="store-locator-clear"
                      onClick={this.handleClear}
                      type="button"
                    >
                      {t("shared:action.clear_form")}
                    </button>
                    {isDefaultLocatorStrategy && (
                      <button
                        className="btn grid__cell--1/2 grid__cell--1/4@widescreen"
                        data-icon="pin_drop"
                        data-quid="store-locator-search"
                        onClick={this.handleSearch}
                        type="button"
                      >
                        {t("shared:action.drop_pin")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case SERVICE_METHOD.CARRYOUT:
      default:
        return (
          <GoogleMapsTypeAhead
            className="grid__cell--1"
            input={address}
            label={t(ADDRESS_FIELDS[ADDRESS.ADDRESS].label)}
            maps={maps}
            name="address"
            onChange={({ address: addressValue, placeId }) =>
              handleChange({
                address: addressValue,
                placeId,
              })
            }
            options={{
              componentRestrictions: {
                country: countryCodeList,
              },
              types: ["geocode"],
            }}
            quidBase="store-locator-address"
          />
        );
    }
  }
}

MX.propTypes = {
  address: PropTypes.string,
  deliveryInstructions: PropTypes.string,
  getSearchCep: PropTypes.func.isRequired,
  getRegions: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  maps: PropTypes.objectOf(PropTypes.any).isRequired,
  marketConfigs: PropTypes.shape({
    DELIVERY_INSTRUCTIONS_MAX_LENGTH: PropTypes.number,
  }).isRequired,
  searchCep: PropTypes.arrayOf(PropTypes.object).isRequired,
  onError: PropTypes.func,
  serviceMethod: PropTypes.oneOf(["Carryout", "Delivery", "Dine In"]),
  setUsesPlaces: PropTypes.func.isRequired,
  locatorStrategy: PropTypes.string.isRequired,
  setLocatorStrategy: PropTypes.func.isRequired,
};

MX.defaultProps = {
  address: "",
  addressType: ADDRESS.LANDED,
  deliveryInstructions: "",
  onError: (error) => {
    //eslint-disable-next-line no-console
    process.env.NODE_ENV !== "production" && console.error(error);
  },
  searchCep: {},
  getSearchCep: () => {},
  serviceMethod: "Carryout",
};

export default MX;
