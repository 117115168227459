//TODO: write tests
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { enableMapSet } from "immer";
import queryString from "qs";
import { connectRoutes } from "redux-first-router";
import { combineEpics, createEpicMiddleware } from "redux-observable";

import addressReducer, { epics as addressEpics } from "ducks/address";
import agentReducer, { epics as agentEpics } from "ducks/agent";
import areaReducer from "ducks/area";
import baseReducer from "ducks/base";
import categoryReducer from "ducks/category";
import cityReducer, { epics as cityEpics } from "ducks/city";
import configurationReducer, {
  epics as configurationEpics,
} from "ducks/configuration";
import cookingInstructionGroupsReducer from "ducks/cookingInstructionGroups";
import cookingInstructionOptionsReducer from "ducks/cookingInstructionOptions";
import couponReducer, { epics as menuCouponEpics } from "ducks/coupon";
import customerReducer, { epics as customerEpics } from "ducks/customer";
import loadingReducer, { epics as loadingEpics } from "ducks/loading";
import marketReducer, {
  epics as marketEpics,
  marketMiddleware,
} from "ducks/market";
import { epics as menuEpics } from "ducks/menu";
import messageReducer, { messageMiddleware } from "ducks/message";
import neighborhoodsReducer, {
  epics as neigborhoodsEpics,
} from "ducks/neighborhoods";
import orderReducer, { epics as orderEpics } from "ducks/order";
import {
  checkEmptyCartMiddleware,
  setPriceOrderLoadingMiddleware,
} from "ducks/order/ducklings/priceValidate";
import orderCouponReducer, { epics as couponEpics } from "ducks/orderCoupon";
import orderProductReducer, {
  epics as orderProductEpics,
} from "ducks/orderProduct";
import orderStatusReducer, {
  epics as orderStatusEpics,
} from "ducks/orderStatus";
import partReducer, { epics as partEpics } from "ducks/part";
import partOptionReducer from "ducks/partOption";
import placesReducer, { epics as placesEpics } from "ducks/places";
import productReducer from "ducks/product";
import regionReducer, { epics as regionEpics } from "ducks/region";
import rncReducer, { epics as rncEpics } from "ducks/rnc";
import rootReducer, { epics as rootEpics } from "ducks/root";
import { epics as routesEpics } from "ducks/routes";
import searchCepReducer, { epics as searchCepEpics } from "ducks/searchCep";
import sessionReducer, {
  addTokenMiddleware,
  checkSessionMiddleware,
  sessionErrorMiddleware,
} from "ducks/session";
import sideReducer from "ducks/side";
import sizeReducer from "ducks/size";
import statesReducer, { epics as statesEpics } from "ducks/states";
import storeReducer, { epics as storeEpics } from "ducks/store";
import storeLocationReducer, {
  epics as storeLocationEpics,
} from "ducks/storeLocation";
import streetCategoriesReducer, {
  epics as streetCategoriesEpics,
} from "ducks/streetCategories";
import streetsReducer, { epics as streetsEpics } from "ducks/streets";
import toppingReducer from "ducks/topping";
import variantsReducer from "ducks/variants";
import waterfallUpsellReducer, {
  epics as waterfallUpsellEpics,
} from "ducks/waterfallUpsell";
import weightedOptionReducer from "ducks/weightedOption";

import { baseApi } from "rtk_redux/api/baseApi";
import { externalApi } from "rtk_redux/api/externalApi";
import { listenerMiddleware } from "rtk_redux/middleware/rtkMiddleware";
import carsideInformationReducer from "rtk_redux/slices/carsideInformationSlice";
import customerInformationFormReducer from "rtk_redux/slices/customerInformationFormSlice";
import customerPageReducer from "rtk_redux/slices/customerPageSlice";
import finishReducer from "rtk_redux/slices/finishSlice";
import futureTimedOrderReducer from "rtk_redux/slices/futureTimedOrderSlice";
import orderHistoryReducer from "rtk_redux/slices/orderHistorySlice";
import pizzaReducer from "rtk_redux/slices/pizzaSlice";
import priceOrderReducer from "rtk_redux/slices/priceOrderSlice";
import serviceMethodReducer from "rtk_redux/slices/serviceMethodSlice";

import { fetch } from "modules/ajax";

import { routesMap } from "./routes";

enableMapSet();

const history = createBrowserHistory({ basename: "order-entry" });
const {
  enhancer: historyEnhancer,
  middleware: historyMiddleware,
  reducer: historyReducer,
} = connectRoutes(history, routesMap, {
  querySerializer: queryString,
});

const reducer = combineReducers({
  address: addressReducer,
  agent: agentReducer,
  area: areaReducer,
  base: baseReducer,
  category: categoryReducer,
  city: cityReducer,
  configuration: configurationReducer,
  cookingInstructionGroups: cookingInstructionGroupsReducer,
  cookingInstructionOptions: cookingInstructionOptionsReducer,
  coupon: couponReducer,
  customer: customerReducer,
  finish: finishReducer,
  futureTimedOrder: futureTimedOrderReducer,
  pizza: pizzaReducer,
  loading: loadingReducer,
  location: historyReducer,
  market: marketReducer,
  message: messageReducer,
  neighborhoods: neighborhoodsReducer,
  searchCep: searchCepReducer,
  order: orderReducer,
  orderCoupon: orderCouponReducer,
  orderProduct: orderProductReducer,
  orderStatus: orderStatusReducer,
  part: partReducer,
  partOption: partOptionReducer,
  places: placesReducer,
  priceOrder: priceOrderReducer,
  product: productReducer,
  region: regionReducer,
  rnc: rncReducer,
  root: rootReducer,
  session: sessionReducer,
  side: sideReducer,
  size: sizeReducer,
  states: statesReducer,
  streetCategories: streetCategoriesReducer,
  streets: streetsReducer,
  store: storeReducer,
  storeLocation: storeLocationReducer,
  topping: toppingReducer,
  variants: variantsReducer,
  waterfallUpsell: waterfallUpsellReducer,
  weightedOption: weightedOptionReducer,
  customerInformationForm: customerInformationFormReducer,
  serviceMethod: serviceMethodReducer,
  customerPage: customerPageReducer,
  carsideInformation: carsideInformationReducer,
  orderHistory: orderHistoryReducer,
  [baseApi.reducerPath]: baseApi.reducer,
  [externalApi.reducerPath]: externalApi.reducer,
});

const epics = combineEpics(
  addressEpics,
  agentEpics,
  cityEpics,
  neigborhoodsEpics,
  searchCepEpics,
  configurationEpics,
  couponEpics,
  customerEpics,
  loadingEpics,
  marketEpics,
  menuEpics,
  menuCouponEpics,
  orderEpics,
  orderProductEpics,
  orderStatusEpics,
  partEpics,
  placesEpics,
  regionEpics,
  rootEpics,
  rncEpics,
  statesEpics,
  streetCategoriesEpics,
  streetsEpics,
  storeEpics,
  storeLocationEpics,
  routesEpics,
  waterfallUpsellEpics
);

const epicMiddleware = createEpicMiddleware(epics, {
  dependencies: {
    fetch,
  },
});

const collectedMiddleware = [
  historyMiddleware,
  marketMiddleware,
  messageMiddleware,
  checkSessionMiddleware,
  addTokenMiddleware,
  sessionErrorMiddleware,
  checkEmptyCartMiddleware,
  setPriceOrderLoadingMiddleware,
  epicMiddleware,
  baseApi.middleware,
  externalApi.middleware,
  listenerMiddleware.middleware,
];

const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware({
    thunk: true, // set true for redux-thunk or RTK Query
    immutableCheck: false, // set true to check for unexpected mutations
    serializableCheck: false, // set true to check for non-serializable values
  }).prepend(collectedMiddleware);

export const store = configureStore({
  reducer,
  middleware,
  devTools: process.env.NODE_ENV === "production" ? false : true,
  enhancers: [historyEnhancer],
});
