import React, { Component } from "react";

import PropTypes from "prop-types";

import RequiredMark from "components/RequiredMark";
import Suggestion from "components/StoreLocator/Strategy/TypeAhead/Suggestion";

import "./TypeAhead.css";

const debounce = (func, delay, immediate) => {
  let timeout;

  return (...args) => {
    const context = this;

    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, delay);

    if (immediate && !timeout) func.apply(context, args);
  };
};

class TypeAhead extends Component {
  constructor(props) {
    super(props);

    this.fetchPredictions = this.fetchPredictions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.debounceFetchPredictions = debounce(
      this.fetchPredictions.bind(this),
      props.debounce
    );
  }

  fetchPredictions() {
    const { input, clearSuggestions, fetchPredictions } = this.props;

    if (input.length) {
      fetchPredictions();
    } else {
      clearSuggestions();
    }
  }

  handleChange({ target: { value } }) {
    const { name, onChange, clearSuggestions } = this.props;
    onChange({ [name]: value });
    !value && clearSuggestions();

    this.debounceFetchPredictions();
  }

  handleSelect(suggestion) {
    const { clearSuggestions, handleSelect } = this.props;
    clearSuggestions();
    handleSelect(suggestion);
  }

  render() {
    const {
      className,
      disabled,
      input,
      label,
      name,
      quidBase,
      suggestions = [],
      required,
    } = this.props;

    return (
      <div
        className={`type-ahead form__control-group ${className}`}
        data-quid={quidBase}
        key={name}
      >
        <label
          className="text-field__label"
          data-quid={`${quidBase}-label`}
          htmlFor={name}
        >
          {required ? <RequiredMark /> : null} {label}
        </label>

        <input
          data-quid={`${quidBase}-input`}
          onChange={this.handleChange}
          placeholder={label}
          name={name}
          type="text"
          value={input}
          disabled={disabled}
          required={required}
        />

        <div className="type-ahead__wrapper">
          <div
            className="type-ahead__options"
            data-quid={`${quidBase}-results`}
          >
            {suggestions.length > 0 &&
              suggestions.map((suggestion, index) => {
                const { description, placeId } = suggestion;
                return (
                  <Suggestion
                    suggestion={suggestion}
                    input={input}
                    key={placeId || description}
                    dataQuid={`${quidBase}-results-${index + 1}`}
                    handleSelect={this.handleSelect}
                  />
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

TypeAhead.propTypes = {
  className: PropTypes.string,
  clearSuggestions: PropTypes.func.isRequired,
  debounce: PropTypes.number,
  disabled: PropTypes.bool,
  input: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  quidBase: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  fetchPredictions: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({ description: PropTypes.string.isRequired })
  ).isRequired,
  required: PropTypes.bool,
};

TypeAhead.defaultProps = {
  className: "",
  debounce: 500,
  disabled: false,
  input: "",
  label: "",
  name: "",
  options: {},
  required: false,
};

export default TypeAhead;
