import React, { Fragment } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import { storeHeadings, storeSubheadings } from "constants/customer";
import isDelivery from "modules/isDelivery";
import kebabCase from "modules/kebabCase";
import { isStoreOnline, sortStores } from "modules/sortStores";
import toggleCollapsible from "modules/toggleCollapsible";

import EstimatedWaitTimes from "components/EstimatedWaitTimes";
import StoreLocator from "components/StoreLocator";

const Locations = ({
  cities,
  currentStoreId,
  currentOrderTime,
  getCities,
  getNeighborhoods,
  getSearchCep,
  getRegions,
  getPlaces,
  getStoreInVariance,
  getStates,
  getStore,
  getStreets,
  getStreetCategories,
  hasSelectedStore,
  locateStores,
  locatedStores,
  isFilterDeliveryOnlyEnabled,
  maps,
  marketCode,
  marketConfigs,
  searchCep,
  neighborhoods,
  regions,
  places,
  currentServiceMethod,
  storeLocatorQuery,
  t,
  states,
  streetCategories,
  streets,
}) => {
  const allowedStoreHeadings = storeHeadings.filter(
    // TODO: fix
    // eslint-disable-next-line react/prop-types
    ({ key }) => marketConfigs.ALLOWED_STORE_FIELDS[key]
  );

  const selectStore =
    ({ storeId, inStoreVariance, store, query }) =>
    () => {
      const { city, neighborhood } = storeLocatorQuery;
      const hasSTDFData = city || neighborhood;
      const allowsManualStoreSelection =
        hasSTDFData &&
        !isFilterDeliveryOnlyEnabled &&
        isDelivery(currentServiceMethod);

      if (!isStoreOnline(store) || inStoreVariance) return;

      if (allowsManualStoreSelection) {
        locateStores({
          query,
          currentServiceMethod,
          isFilterDeliveryOnlyEnabled: true,
          executeQuery: true,
          serviceMethod: currentServiceMethod,
        });
      } else getStore(storeId);
    };

  return (
    <div className="wrapper locations">
      <StoreLocator
        cities={cities}
        countryCode={marketCode}
        getCities={getCities}
        getNeighborhoods={getNeighborhoods}
        getSearchCep={getSearchCep}
        getPlaces={getPlaces}
        getStates={getStates}
        getStreetCategories={getStreetCategories}
        getStreets={getStreets}
        getRegions={getRegions}
        isFilterDeliveryOnlyEnabled={isFilterDeliveryOnlyEnabled}
        locateStores={locateStores}
        maps={maps}
        marketConfigs={marketConfigs}
        neighborhoods={neighborhoods}
        searchCep={searchCep}
        query={storeLocatorQuery}
        regions={regions}
        places={places}
        serviceMethod={currentServiceMethod}
        t={t}
        states={states}
        streetCategories={streetCategories}
        streets={streets}
      />

      <br />

      <header className="title-group">
        <h2 className="title-group__section" data-quid="heading-stores">
          {t("customer:locations.store.title")}
        </h2>
      </header>

      <Fragment>
        {Object.keys(locatedStores).length > 0 ? (
          <table
            className="table--no-stripe table--border-bottom"
            data-quid="located-stores"
          >
            <thead>
              <tr>
                {allowedStoreHeadings.map(({ key, label }) => (
                  <th key={key}>{t(label)}</th>
                ))}

                <th />
              </tr>
            </thead>
            {sortStores(Object.entries(locatedStores), {
              // TODO: fix
              // eslint-disable-next-line react/prop-types
              sortPreference: marketConfigs.STORE_SORT_PREFRENCE,
            }).map(([storeId, store]) => {
              const {
                DEFAULT_DELIVERY_WAIT_TIME,
                DEFAULT_CARRYOUT_WAIT_TIME,
                NEW_EXPERIENCE_FEATURES: { filterAvailableServiceMethods },
              } = marketConfigs;

              if (
                filterAvailableServiceMethods &&
                store.availableServiceMethods.length > 0
              ) {
                store["serviceMethodsDescription"] =
                  store.availableServiceMethods.join(", ");
              }

              const {
                storeVariance,
                carryoutWaitTimeReason,
                deliveryWaitTimeReason,
                serviceMethodEstimatedWaitMinutes = {},
              } = store;
              getStoreInVariance(storeId);
              const showCarryoutStoreVarianceWarning =
                storeVariance && currentServiceMethod === "Carryout";
              let inStoreVariance = false;
              if (showCarryoutStoreVarianceWarning) {
                inStoreVariance = getStoreInVariance({
                  orderTime: currentOrderTime,
                  storeId,
                  serviceMethod: currentServiceMethod,
                });
              }
              const currentEstimatedWaitMinutes =
                serviceMethodEstimatedWaitMinutes[currentServiceMethod];
              const waitTimeReason =
                currentServiceMethod === "Delivery"
                  ? deliveryWaitTimeReason
                  : carryoutWaitTimeReason;

              const defaultWaitTime =
                currentServiceMethod === "Delivery"
                  ? DEFAULT_DELIVERY_WAIT_TIME
                  : DEFAULT_CARRYOUT_WAIT_TIME;

              return (
                <tbody className="collapsible" key={storeId}>
                  <tr
                    className={classNames(
                      "interactive",
                      storeId === currentStoreId &&
                        hasSelectedStore &&
                        "is-active"
                    )}
                    data-quid={`store-${storeId}`}
                    onClick={selectStore({
                      storeId,
                      inStoreVariance,
                      store,
                      query: {
                        ...storeLocatorQuery,
                        latitude: store.latitude,
                        longitude: store.longitude,
                      },
                    })}
                    disabled={!isStoreOnline(store) || inStoreVariance}
                  >
                    {allowedStoreHeadings.map(({ key, label }) => (
                      <td
                        key={key}
                        data-quid={kebabCase(key)}
                        data-th={t(label)}
                      >
                        {store[key]}
                      </td>
                    ))}

                    <td
                      className="text--center icon--toggle"
                      data-hook="toggle-collapsible"
                      data-quid="toggle-details"
                      onClick={toggleCollapsible}
                      onKeyDown={(e) => e}
                      role="presentation"
                    >
                      <span>
                        <span
                          className="toggle--open text--bigger icon-only"
                          data-icon="keyboard_arrow_up"
                        />

                        <span
                          className="toggle--closed text--bigger icon-only"
                          data-icon="keyboard_arrow_down"
                        />
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={storeHeadings.length + 1}>
                      <div className="collapsible__item">
                        <div className="collapsible-content text--center">
                          {showCarryoutStoreVarianceWarning ? (
                            <p>
                              <b>
                                {t("customer:locations.store.store_variance", {
                                  openTime:
                                    storeVariance.storeHoursByServiceMethod[
                                      currentServiceMethod
                                    ].OpenTime,
                                  closeTime:
                                    storeVariance.storeHoursByServiceMethod[
                                      currentServiceMethod
                                    ].CloseTime,
                                })}
                              </b>
                            </p>
                          ) : null}
                          {storeSubheadings.map(({ key, label }) => (
                            <p key={key}>
                              <b>{t(label)}</b>: {store[key]}
                            </p>
                          ))}

                          <p>
                            <b>{t("customer:locations:store:ready_in")}</b>:{" "}
                            <EstimatedWaitTimes
                              currentEstimatedWaitMinutes={
                                currentEstimatedWaitMinutes
                              }
                              defaultWaitTime={defaultWaitTime}
                              t={t}
                              waitTimeReason={waitTimeReason}
                            />
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        ) : (
          <span>{t("customer:locations.store.no_stores_located")}</span>
        )}
      </Fragment>
    </div>
  );
};

Locations.propTypes = {
  cities: PropTypes.objectOf(PropTypes.string).isRequired,
  currentOrderTime: PropTypes.string,
  currentServiceMethod: PropTypes.string,
  currentStoreId: PropTypes.string,
  getCities: PropTypes.func.isRequired,
  getNeighborhoods: PropTypes.func.isRequired,
  getSearchCep: PropTypes.func.isRequired,
  getPlaces: PropTypes.func.isRequired,
  getRegions: PropTypes.func.isRequired,
  getStates: PropTypes.func.isRequired,
  getStore: PropTypes.func.isRequired,
  getStoreInVariance: PropTypes.func.isRequired,
  getStreetCategories: PropTypes.func.isRequired,
  getStreets: PropTypes.func.isRequired,
  hasSelectedStore: PropTypes.bool,
  isFilterDeliveryOnlyEnabled: PropTypes.bool,
  locateStores: PropTypes.func.isRequired,
  locatedStores: PropTypes.objectOf(PropTypes.object),
  maps: PropTypes.objectOf(PropTypes.any).isRequired,
  marketCode: PropTypes.string.isRequired,
  marketConfigs: PropTypes.shape({
    DEFAULT_CARRYOUT_WAIT_TIME: PropTypes.string,
    DEFAULT_DELIVERY_WAIT_TIME: PropTypes.string,
    NEW_EXPERIENCE_FEATURES: PropTypes.objectOf(PropTypes.object),
  }).isRequired,
  neighborhoods: PropTypes.objectOf(PropTypes.string).isRequired,
  searchCep: PropTypes.arrayOf(PropTypes.object).isRequired,
  places: PropTypes.arrayOf(PropTypes.string).isRequired,
  regions: PropTypes.objectOf(PropTypes.string).isRequired,
  storeLocatorQuery: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
    ])
  ).isRequired,
  t: PropTypes.func,
  states: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string }))
    .isRequired,
  streetCategories: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, option: PropTypes.string })
  ).isRequired,
  streets: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      placeId: PropTypes.string,
    })
  ).isRequired,
};

Locations.defaultProps = {
  currentOrderTime: "",
  currentServiceMethod: "",
  currentStoreId: "",
  getStore: () => {},
  hasSelectedStore: true,
  isFilterDeliveryOnlyEnabled: true,
  locatedStores: {},
  t: () => {},
};

export default Locations;
